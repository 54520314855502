@import 'styles/variables.sass'
@import 'styles/breakpoints.sass'

$border-width: 1px

.container
  display: grid
  gap: 1em
  grid-template-columns: 1fr 1fr
  @include breakpoint-up(md)
    grid-template-columns: 1fr 1fr 1fr

.button
  user-select: none
  flex: none !important
  border: $border-width solid var(--bs-gray-400) !important
  border-radius: unset !important
  box-shadow: unset !important

.activeButton
  position: relative
  &::before
    position: absolute
    content:''
    border: 3px solid $primary-color !important
    top: -$border-width
    bottom: -$border-width
    left: -$border-width
    right: -$border-width

  .mark
    position: absolute
    width: 15px
    right: 0.5em
    top: 0.5em
