@import 'styles/variables.sass'
  
input.phoneNumber
  width: 100% !important
  font-size: 1rem !important
  height: 38px !important
  border: 1px solid #ced4da !important
  font-family: $font-family
  padding-left: 60px !important

.buttonCountryCode:global(.flag-dropdown)
  background-color: white
  width: 49px
