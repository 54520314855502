@import 'styles/variables.sass'
  
span.startBoxRightAngles
  border-top-right-radius: 0
  border-bottom-right-radius: 0
  background-color: transparent
  border-right: none

input.inputLeftAngles
  border-top-left-radius: 0
  border-bottom-left-radius: 0


.passwordInput
  padding-right: 32px !important
  background-image: none !important

.eye
  user-select: none
  position: absolute
  top: 50%
  right: 13px
  transform: translate(0, -50%)
