@font-face 
  font-family: 'Roboto'
  font-style: normal
  font-weight: 300
  src: local('Roboto Light'), local('Roboto-Light'), url('../assets/fonts/Roboto-Light.ttf') format('truetype')

@font-face 
  font-family: 'Roboto'
  font-style: normal
  font-weight: 400
  src: local('Roboto Regular'), local('Roboto-Regular'), url('../assets/fonts/Roboto-Regular.ttf') format('truetype')

@font-face
  font-family: 'Roboto'
  font-style: normal
  font-weight: 500
  src: local('Roboto Medium'), local('Roboto-Medium'), url('../assets/fonts/Roboto-Medium.ttf') format('truetype')

@font-face 
  font-family: 'Roboto'
  font-style: normal
  font-weight: 800
  src: local('Roboto Bold'), local('Roboto-Bold'), url('../assets/fonts/Roboto-Bold.ttf') format('truetype')
