@import 'styles/variables.sass'

.image
  display: block
  max-width: 100%
  max-height: 100%
  &.activeImage
    box-shadow: 0 0 0 .25rem rgba($primary-color, 0.25)

.remove
  position: absolute
  top: 0.5em
  left: 0.5em
