@import 'styles/variables.sass'

.mention
  display: inline-block
  padding: 4.2px 7.8px
  background: $primary-color
  color: white
  border-radius: 4px
  font-weight: 400 

.activeMention
  box-shadow: 0 0 0 2px #B4D5FF