@import '../../../styles/variables.sass'

.checkbox
  span, input
    width: 1em
    height: 1em
    margin-top: 0.25em
    vertical-align: top
    background-color: #fff
    background-repeat: no-repeat
    background-position: center
    background-size: contain
    border: 1px solid rgba(0, 0, 0, 0.25)
    appearance: none
    -webkit-print-color-adjust: exact
    float: left
    margin-left: -1.5em
    border-radius: 0.25em
    margin-bottom: 6px
  input:checked + span
    background-color: $primary-color!important
    border-color: $primary-color!important
    &::after
      width: 6px !important
      height: 9px !important
  