.inputWrapper
  position: relative

.icon
  position: absolute
  top: 0
  right: 0
  padding-right: 0.7em
  height: 100%
  display: flex
  align-items: center
  user-select: none
  color: gray
