$background-color: #F8F9FA
$primary-color: #F87A85
$primary-light-color: lighten($primary-color, 50)
$gray-color: #808080
$gray-800: var(--bs-gray-800)
$lynch-color: #606e89
$red-color: #dc3545
$font-family: 'Roboto', sans-serif
$font-weight: 300

$btn-primary-color: white

$oneped-sidebar-width: 240px
$closed-sidebar-width: 66px 

$sm: 576px
$md: 768px
$lg: 992px
$xl: 1200px
$xxl: 1600px

$z-index-loading-overlay: 10
$z-index-modal: 30
$z-index-navbar: 10
$z-index-sidebar-overlay: 7
$z-index-sidebar: 8

$form-control-px: 0.75rem 
$form-control-py: 0.375rem 

:export 
  backgroundColor: $background-color
  primaryColor: $primary-color
  grayColor: $gray-color
  gray800: $gray-800
