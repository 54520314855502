.cell
  width: 100%
  height: 100%
  position: relative
  display: flex
  align-items: center
  justify-content: center
  cursor: pointer
  &:hover
    background: rgba(0,0,0,0.1)
    .button
      opacity: 1

.button
  opacity: 0
  background-color: rgba(255,255,255,0.75)
  position: absolute
  width: 20px
  height: 20px
  top: 0
  right: 0
  border-radius: 3px
  display: flex
  align-items: center
  justify-content: center

