@import 'styles/variables.sass'

$avatar-width: 34px
$dropdown-title-gap: 8px

.dropdown
  .dropdownTitle
    display: flex
    align-items: center
    gap: $dropdown-title-gap
    img
      width: $avatar-width
  :global(.dropdown-menu)
    margin-left: calc($avatar-width + $dropdown-title-gap)
  :global(.dropdown-item):hover
    color: $primary-color !important
    background-color: transparent
