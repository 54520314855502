@import 'styles/variables.sass'

.rcPagination
  display: flex
  justify-content: left
  &::after
    content: none

  li
    display: inline-table
    margin: 0
    border: none !important
    content: none !important
    .button
      display: flex
      justify-content: center
      align-items: center
      border-color: $primary-color!important
      &::after
        content: none
    .prev
      border-top-right-radius: unset
      border-bottom-right-radius: unset
    .page, .jump-prev, .jump-next
      border-radius: unset
      border-left: unset 
    .next
      border-top-left-radius: unset
      border-bottom-left-radius: unset
      border-left: unset 