@import 'styles/variables.sass'
@import 'styles/breakpoints'

$app-padding: 1em

.indentForSidebar
  transition: padding 0.3s ease-in-out
  &.sidebarOpen
    padding-left: calc($oneped-sidebar-width + $app-padding) !important

.content, .appContent
  min-height: 100vh
  padding-top: 1rem
  padding-bottom: 1rem

.appContent
  max-width: $xxl !important
  padding: $app-padding
  padding-left: calc($closed-sidebar-width + $app-padding) !important
