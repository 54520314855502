@import './variables.sass'

body,
html 
  height: 100%
  width: 100%
  margin: 0
  background-color: $background-color
  font-family: $font-family
  font-weight: $font-weight !important

#root
  position: relative
  font-family: $font-family
  min-height: 100%
  background-color: $background-color
  &, * 
    box-sizing: border-box
