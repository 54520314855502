@import 'styles/variables.sass'
@import 'styles/breakpoints.sass'

$modal-padding: 1em

.bodyOpen
  overflow-y: hidden

.primary
  background-color: #f7f8f9 !important

.overlay
  position: fixed
  top: 0px
  left: 0px
  right: 0px
  bottom: 0px
  background: rgba(0, 0, 0, 0.3)
  display: flex
  justify-content: center
  align-items: center
  transition: opacity 300ms ease-in-out
  opacity: 0
  z-index: $z-index-modal
  padding: 20px

.overlayAfterOpen
  opacity: 1

.overlayBeforeClose
  opacity: 0

.drawer
  display: flex
  flex-direction: column
  border-radius: 0 !important
  margin-left: auto
  margin-right: -20px
  min-height: 100vh

.modal
  border-radius: 0

.modal, .drawer
  background: rgb(255, 255, 255)
  outline: none
  box-shadow: 0px 10px 40px rgba(51, 50, 92, 0.03)
  border-radius: 5px
  position: relative
  border: none
  transition: opacity 300ms ease-in-out
  opacity: 0
  max-width: $xl
  width: 100%
  > .modalHeader, > form > *, > div
    padding: $modal-padding
  :global(.rich-editor-toolbar)
    top: -$modal-padding

.drawer 
  .modalHeader, .modalBody
    border-bottom: unset !important

.modalAfterOpen
  opacity: 1

.modalBeforeClose
  opacity: 0

.modalBody
  overflow: auto
.modal .modalBody
  max-height: 80vh

.customModalBody 
  > *
    display: block
    &:not(:last-child)
      padding-bottom: 1em 
