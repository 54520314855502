@import './variables.sass'
@import './breakpoints.sass'

tr.firstStickyColumn
  th:nth-child(1), td:nth-child(1)
    position: sticky
    left: 0
    background-color: white
    z-index: 1
    word-break: break-word
    max-width: 170px
    min-width: 170px
    @include breakpoint-up(lg)
      word-break: normal
      max-width: initial
      min-width: initial
  
  
// react-confirm-alert
.react-confirm-alert
  padding: 15px
  box-sizing: border-box
  max-width: 100%

.react-confirm-alert-body
  font-family: $font-family
  color: black
  text-align: center
  padding: 20px
  box-sizing: border-box
  max-width: 100%

.react-confirm-alert-button-group
  display: flex
  justify-content: center
  align-items: center

  button
    padding: 10px 24px
    font-size: 14px
    cursor: pointer
    font-weight: bold
    border-radius: 6px
    color: white

  button:first-child
    background-color: $primary-color

  button:last-child
    background-color: $red-color

.react-confirm-alert-overlay 
  background: rgba(150, 150, 150, 0.5)

.btn
  font-weight: inherit !important
  &:not(.custom-border)
    border-radius: unset !important

.btn-outline-primary
  color: $primary-color!important

.btn-primary, .btn-outline-primary:hover
  color: $btn-primary-color !important

.disabled-input, .disabled-input *
  cursor: not-allowed !important
  color: $gray-color !important
  background-color: var(--bs-gray-200) !important

.dropdown-remove-toggle .dropdown-toggle::after
  display: none !important

.hide-disabled-times.react-datepicker__time-list-item--disabled
  display: none

.tooltip-bg-secondary > .tooltip-inner
  @extend .bg-secondary

.form-item-feedback-min-height-unset > .invalid-feedback
  min-height: unset !important

.table-header-border > thead
  border-bottom: 2px solid black

.react-datepicker__triangle
  &::before, &::after
    left: unset !important
    right: 0 !important

.rich-editor-content
  overflow: hidden

.rich-editor-content, .form-control
  padding: $form-control-py $form-control-px

b, strong
  font-weight: bold !important

.rc-checkbox
  display: block
