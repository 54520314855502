.container 
  input
    font-weight: 300
  >:not(:global(.react-datepicker__tab-loop), :last-child)
    margin-right: 1em

.customInput
  display: block
  position: relative !important
  input
    padding-right: 2em 

.icon
  position: absolute
  right: 0.7em
  top: 50%
  transform: translate(0, -50%)
  user-select: none
  color: gray

.dateRangeIcon
  min-width: 1em !important
  min-height: 1em !important

