@import './variables.sass'

.flex-1
  flex: 1

.rotate-180
  transform: rotate(180deg)

.max-width-unset
  max-width: unset !important

.border-color-gray-400 
  border-color: var(--bs-gray-400) !important

.min-height-3
  min-height: 1.5em !important

.min-height-fit-content
  min-width: fit-content

.font-weight-inherit
  font-weight: inherit !important

.color-inherit
  color: inherit !important

.w-max-content
  width: max-content

.font-weight-normal
  font-weight: 400 !important

.cursor-pointer
  cursor: pointer
  user-select: none

.icon-4
  width: 4em
  height: 4em

div.rich-editor-read-only
  border: unset !important
  > div
    padding: unset !important

.line-height-1
  line-height: 1 !important

.table-text-center 
  thead, tbody
    text-align: center
    vertical-align: middle !important

.table-smaller-text
  font-size: 0.9em
