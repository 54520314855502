
@import 'styles/variables.sass'

.blockquote
  position: relative
  padding-left: 8px
  &::before
    position: absolute
    content:''
    top: 0
    bottom: 0
    left: 0
    width: 1px
    border: 1px solid $gray-color

div.readOnly
  border: unset