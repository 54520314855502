.container
  min-height: 100vh !important
  min-height: -webkit-fill-available
  .row
    position: relative
    z-index: 1
  .background
    position: fixed
    z-index: 0
    top: 0
    bottom: 0
    left: 0
    right: 0

    background-image: url("../../assets/icons/booking-request-bg.png")
    background-size: cover


