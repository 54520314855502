.btn
  position: relative
  &.endIcon
    display: flex
    align-items: center
    justify-content: center
    svg
      margin-left: 8px
  // min-width: 140px
  
.btnLoading
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  display: flex
  align-items: center
  justify-content: center
