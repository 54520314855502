$max-height-of-body-cell: 200
.body
  position: relative
  .hiddenBody
    max-height: #{$max-height-of-body-cell}px
    overflow: hidden
    text-overflow: ellipsis
    line-clamp: 4
    -webkit-line-clamp: 4
    display: -webkit-box
    -webkit-box-orient: vertical
  .eye
    position: absolute
    right: 0
    top: 0
    z-index: 1

:export
  maxHeightOfBodyCell: $max-height-of-body-cell

.code
  max-width: 600px
  background: #ececec
  padding: 5px
  white-space: break-spaces
