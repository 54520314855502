$displayed-lines: 5

.card
  height: 100%
  .subject
    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis
  .body 
    max-height: 300px
    overflow: hidden
    text-overflow: ellipsis
    line-clamp: $displayed-lines
    -webkit-line-clamp: $displayed-lines
    display: -webkit-box
    -webkit-box-orient: vertical
