@import 'styles/variables.sass'
@import 'styles/breakpoints.sass'

$sidebar-bg-color: #fff

.sidebar
  display: block
  background-color: $sidebar-bg-color
  position: fixed
  left: 0
  padding-top: 0
  padding-bottom: 0
  height: 100%
  width: $closed-sidebar-width
  z-index: $z-index-sidebar
  transform: translateX(-$oneped-sidebar-width)
  transition: transform 0.3s ease-in-out
  transition: width 0.3s ease-in-out
  box-shadow: 4px 6px 24px rgba(0, 0, 0, 0.05)
  &.sidebarOpen
    width: $oneped-sidebar-width
  transform: translateX(0)

.sidebarContent
  display: flex
  flex-direction: column
  height: 100%
  & *
    max-width: 100%
.sidebarMenu
  list-style: none
  margin: 0
  padding: 0

.sidebarMenuItem
  height: 50px
  position: relative
  display: flex
  align-items: center
  background-color: transparent
  font-size: 1em
  text-decoration: none
  cursor: pointer
  transition: width 0.3s ease-in-out
  text-align: left
  &:hover
    > .sidebarMenuItemIcon, > .sidebarMenuItemLabel
      color: $primary-color !important
  .sidebarOpen &
    transition: width 0.3s ease-in-out
    width: 230px
    border-radius: 8px
  .sidebarMenuItemIcon
    min-width: 16px
    display: flex
    align-items: center
    margin: 0 auto
    position: relative
    .sidebarOpen &
      margin: 0
  .sidebarMenuItemLabel
    display: none
    white-space: nowrap
    margin-left: 8px
    .sidebarOpen &
      display: block

.mainMenu
  overflow-y: auto
  overflow-x: hidden
  flex: 1 0 auto
  z-index: 1
  width: 100%


$toggleSidebarBtnSize: 50px
.toggleSidebarBtn
  position: absolute
  bottom: 100px
  z-index: 1
  // bottom: 50px
  background: $sidebar-bg-color !important
  box-shadow: 4px 6px 24px rgba(0, 0, 0, 0.05) !important
  right: -$toggleSidebarBtnSize / 2
  width: $toggleSidebarBtnSize
  height: $toggleSidebarBtnSize
  border-radius: 50% !important
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out !important
